export type QueryTarget = keyof typeof queryTarget;

export const queryTarget = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    mobile_tablet: 570,
    tablet: 800,
    laptop_tablet_landscape: 1024,
    smallDesktop: 1366,
    desktop: 1680,
};

export const device = {
    mobileS: `(min-width: ${queryTarget.mobileS}px)`,
    mobileM: `(min-width: ${queryTarget.mobileM}px)`,
    mobileL: `(min-width: ${queryTarget.mobileL}px)`,
    mobile_tablet: `(min-width: ${queryTarget.mobile_tablet}px)`,
    tablet: `(min-width: ${queryTarget.tablet}px)`,
    laptop: `(min-width: ${queryTarget.laptop_tablet_landscape}px)`,
    laptopS: `(min-width: ${queryTarget.smallDesktop}px)`,
    desktop: `(min-width: ${queryTarget.desktop}px)`,
};
